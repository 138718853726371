import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Map from "../components/map"

const stores = [
  // Alenquer
  { name: "Sede/Fábrica", lat: 39.040523246385426, lng: -8.99509388173193},
  { name: "Abridoce", lat: 39.14443791268433, lng: -9.018193593598188},
  { name: "Bar da Serra", lat: 39.179219951996345, lng: -9.049441484429622 },
  { name: "Adega Vila Verde", lat: 39.085209528021004, lng: -9.101219802263122 },
  { name: "Talho do Alfredo", lat: 39.093981127896186, lng: -9.112648178253698 },
  { name: "Padaria do Mercado", lat: 39.051017621853006, lng: -9.0070296291851 },
  // Torres Vedras
  { name: "Casa da Avó Gama", lat: 39.09302070663255, lng: -9.2599437371737 },
  // Arruda dos Vinhos
  { name: "Casa Cavaco", lat: 38.98304995383348, lng: -9.077715805102573 },
  // Lisboa
  { name: "Pastelaria Astro", lat: 38.738068994975954, lng: -9.134497478070216 },
  { name: "Charcutaria Riviera", lat: 38.75355825349863, lng: -9.142156888782996 },
  { name: "Portela Cafés", lat: 38.75324401724088,lng: -9.144953159920963 },
  { name: "Rosmaninho", lat: 38.71152674904848, lng: -9.227501843234993 },
  { name: "Talho do Carlos", lat: 38.744314804120194,lng: -9.178735035330133 },
  { name: "Pátria Rústica Alfama", lat: 38.7119909573656, lng: -9.128191088757553 },
  { name: "Pátria Rústica Madalena", lat: 38.71015659015033, lng: -9.135010931086422 },
  { name: "Garrafeira Agrovinhos", lat: 38.704473462301216, lng: -9.175720302812087 },
  { name: "A Vida Portuguesa - Chiado", lat: 38.71007360818276, lng: -9.141047679558541 },
  { name: "A Vida Portuguesa - Mercado da Ribeira", lat: 38.70671975573497, lng: -9.14587592864268 },
  { name: "A Vida Portuguesa - Intendente", lat: 38.72104841393574, lng: -9.135119485194604 },
  { name: "HM Caneira Negrais", lat: 38.73147503056865, lng: -9.144383071923382 },
  // Cascais
  { name: "HM Caneira Negrais", lat: 38.70182393433953, lng: -9.421911388208125 },
  // Oeste
  { name: "Mercearia Pena", lat: 39.403776839558184, lng: -9.135599299581537 },
  { name: "Loja da Amélia - Ericeira", lat: 38.96394048506502, lng: -9.416473302244377 },
  { name: "Loja da Amélia - Mafra", lat: 38.937600100862916, lng: -9.329615873409493 },
  { name: "Horta Doce", lat: 39.20021313551128, lng: -9.280621873402707 },
  // Rio Maior
  { name: "Loja do Sal", lat: 39.3647342793939, lng: -8.944551773398427 },
  { name: "Azeite Fonte da Bica", lat: 39.36422829953168, lng: -8.950838871201508 },
  // Santo Estevão
  { name: "Aroma do Campo", lat: 38.860869014145734, lng: -8.747184812353456 },
  // Margem Sul
  { name: "Sauvage Gourmet", lat: 38.67259938247919, lng: -9.16135281759412 },
  // Algarve
  { name: "Mar d'Estórias", lat: 37.100185136901125, lng: -8.671659817633454 },
  // Porto
  { name: "Mercearia das Flores", lat: 41.1440990082877, lng: -8.61342202314563 },
  { name: "Casa Chinesa", lat: 41.14940969736225, lng: -8.607733031022184 },
  { name: "Alma Portuguesa", lat: 41.17615095416785, lng: -8.586362665653578 },
  { name: "Creative Gourmet", lat: 41.343627485012235, lng: -8.502252544510208 }
];

const StoresPage = () => (
  <Layout>
    <Seo title="Onde comprar" />
    <h1>Onde comprar</h1>
    <p>Os nossos produtos são vendidos em lojas gourmet em todo o país, veja no mapa a loja mais próxima de si.</p>
    <br/>
    <div style={{ height: '60vh', width: '100%' }}>
      <Map places={stores} />
    </div>
  </Layout>
)

export default StoresPage
