import React, { Component } from "react"
import GoogleMapReact from 'google-map-react';

const getInfoWindowString = (place) => `
    <div>
      <div style="font-size: 16px;">
        ${place.name}
      </div>
    </div>`;

const handleApiLoaded = (map, maps, places) => {
    const markers = [];
    const infowindows = [];
  
    places.forEach((place) => {
      markers.push(new maps.Marker({
        position: {
          lat: place.lat,
          lng: place.lng,
        },
        map
      }));
  
      infowindows.push(new maps.InfoWindow({
        content: getInfoWindowString(place),
      }));
    });
  
    markers.forEach((marker, i) => {
      marker.addListener('click', () => {
        infowindows[i].open(map, marker);
      });
    });
  };

class Map extends Component {
    constructor(props) {
        super(props);
        const { places } = props;
        this.state = {
          places: places
        };
    }

    render() {
        const { places } = this.state;

        return (
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyAQPqf2EblpMnwndw42j4G263oik7peWmo"}}
                defaultCenter={{lat: 39.04, lng: -8.99}}
                defaultZoom={8}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps, places)}
            />
        );
    }
}

export default Map;